import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Registration from '../registration/Registration';
import './Landing.css';

const Landing = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const { alreadyRegistered, customer, owner } = location.state;

  // Get geolocation coordinates on component mount
  useEffect(() => {
    getGeoLocation();
  }, []);

  const getGeoLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          localStorage.setItem('geoLocation', JSON.stringify({ latitude, longitude }));
          setError(null); // Clear any previous errors
        },
        (err) => {
          setError(err.message);
          localStorage.removeItem('geoLocation');
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  const handleNavigateToSummary = async (userType) => {
    setLoading(true);
    setError(null);
    try {
      var idToken = localStorage.getItem('idToken');
      const authnReq = {
        idToken,
        userType
      };
      const response = await fetch('https://api.desispicyexpress.com/express/api/v1/authn/token', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(authnReq),
      });

      setLoading(false);
      if (!response.ok) {
        setLoading(false);
        console.error('Network response was not ok for JWT Token.');
        throw new Error('Network response was not ok for JWT Token.');
      }

      const data = await response.json();
      localStorage.setItem('jwtToken', JSON.stringify(data.payload));
      console.log('Authentication successful for JWT Token.');
      // Navigate to summary page
      navigate('/summary', { state: data.payload });
    } catch (error) {
      setLoading(false);
      console.error('Failed to authenticate user for JWT Token.', error);
      setError('Failed to authenticate user for JWT Token.');
    }
  };

  return (
    <div className="landing-container">
      {alreadyRegistered ? (
        <div className="options-container">
          <p>Welcome back! Choose your type:</p>
          <div className="button-container">
            {customer && <button className="type-button" onClick={() => handleNavigateToSummary('1')}>Customer</button>}
            {owner && <button className="type-button" onClick={() => handleNavigateToSummary('2')}>Owner</button>}
          </div>
        </div>
      ) : (
        <Registration />
      )}
    </div>
  );
};

export default Landing;
