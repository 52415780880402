import React, { useState } from 'react';
import { Form, Input, Button, DatePicker, Select, Typography, Row, Col, message } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment'; 
import './Registration.css';

const { Title } = Typography;
const { Option } = Select;

const Registration = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userType, setUserType] = useState(null); // Added userType state
  const navigate = useNavigate();
  const location = useLocation();

  const { loginId } = location.state;

  // Handle form submission after validation
  const onFinish = async (values) => {
    setLoading(true);
    setError(null);
    try {
      
      console.log('Form Values:', values);
      
      const { firstName, lastName, dateOfBirth, gender, phone } = values;
      var idToken = localStorage.getItem('idToken');
      const geoLocation = JSON.parse(localStorage.getItem('geoLocation'));
      const latitude = geoLocation.latitude;
      const longitude = geoLocation.longitude;
      const registrationRequest = {
        idToken,
        loginId,
        email: loginId,
        latitude,
        longitude,
        firstName,
        lastName,
        dateOfBirth: dateOfBirth.toISOString().split('T')[0],
        gender,
        phone,
        userType, 
      };

      console.log('registrationRequest', registrationRequest);
      const response = await fetch('https://api.desispicyexpress.com/express/api/v1/register/user', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(registrationRequest),
      });

      setLoading(false);

      if (!response.ok) {
        setLoading(false);
        console.error('Registration Failed.', error);
        setError('Registration Failed.');
        throw new Error('Registration Failed.');
      }

      const data = await response.json();
      localStorage.setItem('jwtToken', JSON.stringify(data.payload));
      message.success('Account created successfully!');
      navigate('/landing', { replace: true }, { state: data.payload });
      
    } catch (error) {
      setLoading(false);
      console.error('Registration Failed with Exception.', error);
      setError('Registration Failed with Exception.');
    }
  };

  // Function to handle userType selection
  const handleUserType = (type) => {
    setUserType(type); // Set userType in the state
  };

  return (
    <div className="signup-container">
      <Row justify="center" align="middle" style={{ height: '100vh' }}>
        <Col xs={24} sm={18} md={12} lg={10} xl={8}>
          <div className="signup-box">
            <Title level={1} style={{ textAlign: 'center' }}>
              Register
            </Title>
            <Form
              form={form}
              layout="vertical"
              onFinish={onFinish}  // Call the onFinish function here
              autoComplete="off"
              className="signup-form"
            >
              {/* Name Fields */}
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="firstName"
                    label="First Name"
                    rules={[{ required: true, message: 'Please enter your first name!' }]}
                  >
                    <Input placeholder="First name" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="lastName"
                    label="Last Name"
                    rules={[{ required: true, message: 'Please enter your last name!' }]}
                  >
                    <Input placeholder="Last name" />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  {/* Date of Birth */}
                  <Form.Item
                    name="dateOfBirth"
                    label="Date of Birth"
                    rules={[{ required: true, message: 'Please select your date of birth!' }]}
                  >
                    <DatePicker
                      placeholder="Select Date"
                      style={{ width: '100%' }}
                      format="DD/MM/YYYY"
                      disabledDate={(current) => current && current > moment().endOf('day')} // Disable future dates
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                    {/* Gender */}
                    <Form.Item
                      name="gender"
                      label="Gender"
                      rules={[{ required: true, message: 'Please select your gender!' }]}
                    >
                      <Select placeholder="Select Gender">
                        <Option value="Male">Male</Option>
                        <Option value="Female">Female</Option>
                        <Option value="Other">Other</Option>
                      </Select>
                    </Form.Item>
                </Col>
              </Row>

              {/* Phone Number */}
              <Form.Item
                name="phone"
                label="Phone Number"
                rules={[
                  { 
                    required: true, 
                    message: 'Please enter your phone number!' 
                  },
                  {
                    pattern: /^[0-9]{10}$/, 
                    message: 'Please enter a valid 10-digit phone number'
                  }
                ]}
              >
                <Input placeholder="Phone number" />
              </Form.Item>

              {/* Create Account Button for Customer */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="create-account-button"
                  onClick={() => handleUserType('1')} // Set userType to Customer
                >
                  Create Customer Account
                </Button>
              </Form.Item>

              {/* Create Account Button for Owner */}
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  className="create-account-button"
                  onClick={() => handleUserType('2')} // Set userType to Owner
                >
                  Create Owner Account
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Registration;
